.footer {
  text-align: center;
  padding-bottom: 20px;
  &__links {
    margin-top: 40px;
  }
  &__link {
    margin-bottom: 40px;
    &-title {
      margin-bottom: 12px;
    }
    &-href {
      font-size: 12px;
      line-height: 24px;
    }
  }
  &__copy {
    font-size: 12px;
    line-height: 24px;
    color: $primary-950;
    opacity: 0.4 !important;
  }

  @include table {
		padding-bottom: 120px;
    &__logo-img {
			height: 48px;
      svg {
        width: 340px;
        height: 48px;
      }
    }
		&__links {
			margin-top: 80px;
			@include flex(center, flex-start);
		}
		&__link {
			margin: 0 16px 80px;
			max-width: 452px;
			width: 100%;
			&-title {
				margin-bottom: 24px;
			}
		}
		&__link:nth-child(2) {
			max-width: 296px;
			width: 100%;

		}
		&__copy {
			font-size: 18px;
			line-height: 26px;
		}
  }
	@include desktop {
		&__logo-img {
			height: 64px;
      svg {
        width: 450px;
        height: 64px;
      }
    }
	}
}
