// Sizes
$container: 1920px;
$xxl-min: 1400px;
$xl-min: 1200px;
$lg-min: 992px;
$md-min: 768px;
$sm-min: 576px;

// Main colors
$white-bg: #fff;
$primary-50: #F4F7FB;
$primary-100: #E9EEF5;
$primary-200: #CDDCEA;
$primary-300: #A1BED8;
$primary-400: #6F9CC1;
$primary-500: #4D80AA;
$primary-600: #3A668F;
$primary-700: #305274;
$primary-800: #2B4661;
$primary-900: #2B4158;
$primary-950: #1B2736;

// Other colors
$orange-dark: #C16C41;
$orange-extra: #FBB997;
$orange-light: #FEEFE7;
$black: #3D3734;
$gray-content: #B1AFAE;
$gray-extra: #E4E4E4;
$gray-line: #ECEBEB;
$gray-bg: #F7F7F7;
$white: #ECEBEB;
$green: #60B286;
$green-light: #E8F3ED;
$red: #ED2424;
$red-light: #FDECEB;
$modal-bg: rgba(#383736, .7);

//Fonts
$Outfit_Bold: 'Outfit-Bold';
$Outfit_SemiBold: 'Outfit-SemiBold';
$Outfit_Medium: 'Outfit-Medium';
$Outfit_Regular: 'Outfit-Regular';

// Transition
$transition: all ease-in-out .3s;
$show: cubic-bezier(0, .55, .45, 1);