#map {
  padding-bottom: 64px;
	.map__inner {
		position: relative;
	}
  .map__title {
    margin-bottom: 24px;
  }
  .map__list-item {
    @include flex(flex-start);
    margin-bottom: 16px;
  }
  .map__list-item__title {
    padding-left: 16px;
  }
  .map__list-item__image-wrapper {
    @include size(32px, 32px, 50%);
    @include flex();
    background-color: $primary-900;
  }
	.map__list-item__image svg {
		width: 12px;
		height: 12px;
	}
  .map__image {
    text-align: center;
		&--mobile svg {
			max-width: 350px;
			width: 100%;
			height: 230px;
		}
		&--desktop {
			display: none;
		}
  }
  .map__countries {
    display: grid;
    grid-template-columns: repeat(4, 3fr);
    justify-content: center;
    gap: 8px;
    margin-top: 16px;
  }
  .map__country {
    @include flex(flex-start, center);
    &-title {
      font-size: 14px;
      line-height: 24px;
      color: $primary-800;
      padding-left: 8px;
    }
    &-image {
      width: 16px;
      height: 12px;
    }	
  }
	.map__country:nth-child(3) .map__country-image {
		margin-left: 7px;
	}

  @include table {
		max-width: 1608px;
		width: 100%;
		margin: 0 auto;
		.map__info {
			position: absolute;
			top: 0;
			left: 0;
			z-index: 2;
		}
		.map__title {
			margin-bottom: 36px;
		}
		.map__list-item {
			margin-bottom: 24px;
		}
		.map__list-item__image-wrapper {
			@include size(40px, 40px, 50%);
		}
		.map__list-item__image svg{
			width: 16px;
			height: 16px;
		}
    .map__countries-wrapper {
      display: none;
    }
    .map__image--desktop {
      display: block;
			padding-top: 50px;
			svg {
				width: 100%;
				height: 100%;
			}
			.country {
				cursor: pointer;
				transition: $transition;
			}
			.country:hover {
				fill: $primary-500;
			}
    }
  }
	@include desktop {
		.map__title {
			margin-bottom: 48px;
		}
		.map__list-item {
			margin-bottom: 32px;
		}
		.map__image--desktop {
			padding-top: 0;
			svg {
				height: 1108px;
			}
		}
	}
}
