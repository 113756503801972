html {
  position: relative;
  height: 100%;
}

body {
	width: 100%;
  font-size: 16px;
  line-height: 28px;
  font-family: $Outfit_Regular, sans-serif;
  color: $primary-950;
  overflow-x: hidden;
  background-color: $white-bg;
  @include table {
    font-size: 18px;
    line-height: 32px;
  }
  @include desktop {
    font-size: 20px;
    line-height: 36px;
  }
}

h1,
h2,
h3,
h4 {
  font-family: $Outfit_Bold, sans-serif;
}

h2 {
  font-size: 40px;
  line-height: 52px;
  @include table {
    font-size: 70px;
    line-height: 80px;
  }
  @include desktop {
    font-size: 100px;
    line-height: 108px;
  }
}

h3 {
  font-size: 30px;
  line-height: 42px;
  @include table {
    font-size: 48px;
    line-height: 54px;
  }
  @include desktop {
    font-size: 56px;
    line-height: 64px;
  }
}

h4 {
  font-size: 24px;
  line-height: 28px;
  @include table {
    font-size: 32px;
    line-height: 38px;
  }
  @include desktop {
    font-size: 40px;
    line-height: 48px;
  }
}

h5 {
  font-size: 18px;
  line-height: 24px;
  @include table {
    font-size: 21px;
    line-height: 38px;
  }
  @include desktop {
    font-size: 24px;
    line-height: 32px;
  }
}

a {
  color: $primary-950;
  transition: $transition;
  &:hover {
    color: $primary-500;
  }
}

section {
  position: relative;
  overflow: visible;
}

.container {
  max-width: $container;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;

  @include desktop {
    padding: 0 32px;
  }
}

img {
  object-fit: cover;
}

.logo {
  cursor: pointer;
	svg {
		width: 225px;
		height: 32px;
	}
  @include desktop {
		svg {
			width: 281px;
			height: 40px;
		}
  }
}
