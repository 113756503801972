#preview {
  padding: 80px 0 64px;

  .preview__inner {
    position: relative;
  }
  .preview__main {
    &-title-wrapper,
    &-text {
      margin-bottom: 24px;
      width: 100%;
    }
    &-title-wrapper {
      position: relative;
      overflow: hidden;
      height: 50px;
    }
    &-title {
      position: absolute;
      bottom: -50px;
      left: 0;
      white-space: nowrap;
      font-size: 52px;
      line-height: 52px;
    }
  }
  .preview__images {
    margin: 40px 0 105px;
    position: relative;
  }
  .preview__sticker {
    position: absolute;
    top: -16px;
    left: 0;
    height: 64px;
    width: 66px;
    transform: scale(0);
    animation-name: scale100, rotate2d;
    animation-duration: 1s, 6s;
    animation-iteration-count: 1, infinite;
    animation-fill-mode: forwards, none;
    animation-timing-function: ease-in-out, linear;
    svg {
      height: 64px;
      width: 66px;
    }
    &.mobileAnim {
      animation-delay: 0.7s, 1.7s;
    }
    &.desktopAnim {
      animation-delay: 2s, 3s;
    }
  }
  .preview__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    transform: scale(1.1);
    &-1 {
      animation: zoomOut 2s ease-in-out forwards 800ms;
    }
    &-2 {
      animation: zoomOut 2s ease-in-out forwards 1000ms;
    }
    &-wrapper {
      width: 198px;
      height: 237px;
      overflow: hidden;
    }
    &-1-wrapper {
      position: absolute;
      left: 0;
      top: 65px;
      z-index: 1;
    }
    &-2-wrapper {
      margin-right: 0;
      margin-left: auto;
    }
  }
  .preview__whyUs {
    &-title-wrapper {
      margin-bottom: 16px;
      position: relative;
      overflow: hidden;
      height: 28px;
    }
    &-title {
      position: absolute;
      bottom: -28px;
      left: 0;
    }
  }

  @media screen and (min-width: 450px) {
    .preview__image-wrapper {
      width: 248px;
      height: 317px;
    }
  }
  @include phone {
    .preview__image-wrapper {
      width: 380px;
      height: 500px;
    }
  }
  @include table {
    padding: 100px 0 168px;
    .preview__image-wrapper {
      width: 530px;
      height: 650px;
    }
    .preview__main {
      &-title-wrapper {
        height: 126px;
        margin-bottom: 56px;
      }
      &-title {
        bottom: -126px;
        font-size: 126px;
        line-height: 126px;
      }
    }
    .preview__whyUs {
      &-title-wrapper {
        height: 38px;
      }
      &-title {
        bottom: -38px;
      }
    }
    .preview__sticker,
    .preview__sticker svg {
      width: 86px;
      height: 84px;
    }
  }
  @include desktop {
    padding: 120px 0 272px;
    .preview__main {
      margin-bottom: 72px;
    }
    .preview__images {
      position: absolute;
      right: 0;
      top: 0;
    }
    .preview__image-wrapper {
      width: 330px;
      height: 650px;
    }
    .preview__image-1-wrapper {
      left: -64%;
      top: 20%;
    }
    .preview__image-2-wrapper {
      margin-right: 0;
    }
    .preview__sticker {
      top: auto;
      left: auto;
      bottom: -102px;
      right: 0;
      width: 104px;
      height: 100px;
      svg {
        width: 104px;
        height: 100px;
      }
    }
    .preview__main-text,
    .preview__whyUs-text {
      max-width: 552px;
    }
    .preview__whyUs {
      &-title-wrapper {
        height: 48px;
      }
      &-title {
        bottom: -48px;
      }
    }
  }
  @media screen and (min-width: 1350px) {
    .preview__image-wrapper {
      width: 430px;
      height: 515px;
    }
    .preview__sticker {
      bottom: -232px;
    }
  }
  @media screen and (min-width: 1650px) {
    .preview__main {
      margin-bottom: 180px;
    }
    .preview__main-text,
    .preview__whyUs-text {
      max-width: 726px;
    }
    .preview__image-wrapper {
      width: 530px;
      height: 650px;
    }
    .preview__sticker {
      bottom: -122px;
    }
  }
  @media screen and (min-width: 1850px) {
    .preview__main {
      &-title-wrapper {
        height: 200px;
        margin-bottom: 88px;
      }
      &-title {
        bottom: -200px;
        font-size: 200px;
        line-height: 200px;
      }
    }
    .preview__image-wrapper {
      width: 608px;
      height: 728px;
    }
    .preview__image-1-wrapper {
      left: -73%;
      top: 19%;
    }
    .preview__image-1-wrapper {
      left: -64%;
      top: 22%;
    }
    .preview__image-2-wrapper {
      margin-top: -80px;
    }
    .preview__sticker {
      bottom: -220px;
    }
  }
}
