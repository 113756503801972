#advantages {
  position: relative;
  padding-bottom: 175px;
  margin-bottom: 195px;
  .advantages__main {
    display: flex;
    flex-direction: column;
  }
  .advantages__title {
    width: 100%;
  }
  .advantages__title,
  .advantages__list-item {
    margin-bottom: 40px;
  }
  .advantages__item-title {
    margin-bottom: 12px;
  }
  .advantages__additional {
    display: flex;
    flex-direction: column;
    &-image {
      height: 200px;
      width: 100%;
      margin-bottom: 64px;
    }
  }
  .advantages__scrolling-bar:last-child {
    bottom: -75px;
  }

	@include table {
		padding-bottom: 371px;
		margin-bottom: 418px;
    .advantages__additional-image {
      height: 300px;
    }
		.advantages__scrolling-bar:last-child {
			bottom: -150px;
		}
	}
	
  @include desktop {
    padding-bottom: 567px;
		margin-bottom: 640px;
    .advantages__main {
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 120px;
    }
    .advantages__title {
      max-width: 550px;
      margin-bottom: 0;
    }
    .advantages__list {
      margin-top: 33px;
      padding-left: 20px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 22px;
    }
    .advantages__list-item {
      margin-bottom: 0;
    }
    .advantages__additional {
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;
      &-title {
        max-width: 550px;
        width: 100%;
      }
      &-image {
        order: 1;
        max-width: 776px;
        height: 440px;
        margin-bottom: 0;
        padding-left: 20px;
      }
    }
		.advantages__scrolling-bar:last-child {
			bottom: -240px;
		}
  }
  @media screen and (min-width: 1550px) {
    .advantages__list {
      gap: 92px;
    }
    .advantages__additional-image {
      max-width: 1076px;
    }
  }
}
