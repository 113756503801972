.scrolling-bar {
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  overflow: hidden;
  position: absolute;
  .block {
    @include flex();
  }
  .block__text {
    font-family: $Outfit_Bold, sans-serif;
    font-size: 55px;
    line-height: 55px;
    text-transform: uppercase;
    white-space: nowrap;
  }
  .block__image {
    margin: 10px 22px 0;
    svg {
      width: 46px;
      height: 44px;
    }
  }
  &__block-wrapper {
    height: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    will-change: transform;
    animation: scrollText 150s linear infinite -150s;

    &:nth-child(2) {
      animation: scrollTextNext 150s linear infinite -75s;
    }
  }
  &__block-wrapper.reverse {
    animation: reverseScrollText 150s linear infinite -150s;

    &:nth-child(2) {
      animation: reverseScrollTextNext 150s linear infinite -75s;
    }
  }

  @include table {
    &__block-wrapper {
      animation: scrollText 300s linear infinite -300s;

      &:nth-child(2) {
        animation: scrollTextNext 300s linear infinite -150s;
      }
    }

    &__block-wrapper.reverse {
      animation: reverseScrollText 300s linear infinite -300s;

      &:nth-child(2) {
        animation: reverseScrollTextNext 300s linear infinite -150s;
      }
    }
    .block__text {
      font-size: 127px;
      line-height: 127px;
    }
    .block__image {
      margin: 15px 50px 0;
      svg {
        width: 106px;
        height: 102px;
      }
    }
  }

  @include desktop {
    .block__text {
      font-size: 200px;
      line-height: 200px;
    }
    .block__image {
      margin: 20px 80px 0;
      svg {
        width: 166px;
        height: 160px;
      }
    }
  }
}
