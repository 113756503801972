@font-face {
  font-family: $Outfit_Bold;
  src: url('../fonts/Outfit-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: $Outfit_SemiBold;
  src: url('../fonts/Outfit-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: $Outfit_Medium;
  src: url('../fonts/Outfit-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: $Outfit_Regular;
  src: url('../fonts/Outfit-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
